import { connect } from 'react-redux'
import { getIsBundlePricing } from '../reducers/fetchSsoReducers'

const TaxLine = ({ isBundlePricing, currencySymbol, amount, currency }) => {
  return `Taxes, fees & port expenses ${
    isBundlePricing ? 'included' : ''
  }: ${currencySymbol}${amount} ${currency}`
}

const mapStateToProps = state => {
  return {
    isBundlePricing: getIsBundlePricing(state)
  }
}

export default connect(mapStateToProps)(TaxLine)
