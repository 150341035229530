import { cloneDeep, get } from 'lodash'
import {
  FETCH_PACKAGES_BEGIN,
  FETCH_PACKAGES_SUCCESS,
  FETCH_PACKAGES_FAILURE,
  CLEAR_PACKAGE_DATA
} from '../actions/fetchPackages'
import { getPackageUnsupportedQualifierPrices } from './unsupportedQualifierPricesReducer'
import formatRequestForPricing from '../utilities/formatRequestForPricing'
import getCruiseTabularPricing from '../utilities/getCruiseTabularPricing'
import { getAgentCountry } from './fetchSsoReducers'

const initialState = {
  loading: false,
  error: null
}

export default function fetchPackagesReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_PACKAGES_BEGIN:
      // Request begin: set loading true and pass urlData to state
      return {
        ...state,
        loading: true,
        error: null
      }

    case FETCH_PACKAGES_SUCCESS:
      // Request success: set loading false, and display request data
      return {
        ...state,
        loading: false,
        availablePackages: action.payload
      }

    case FETCH_PACKAGES_FAILURE:
      // Request failed: set loading false, save & display error
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        availability: []
      }

    case CLEAR_PACKAGE_DATA:
      return initialState

    default:
      return state
  }
}

export function getPackagesDetails(
  state,
  isCruiseTour,
  cruiseIdentifier,
  isLandPackage,
  landPackageIdentifier
) {
  const stateKey = isCruiseTour ? 'cruiseToursData' : 'packagesData'

  const cruiseData = cloneDeep(
    get(state, [stateKey, 'availablePackages', cruiseIdentifier], {})
  )

  const { transactionData } = state.ssoData
  const agencyId = transactionData.agency.agencyId
  const officeCode = transactionData.officeCode

  // hasUnsupportedQualifiers is returned when the api is unable to returned cached results.
  // this get pretty complicated in the app as we need the tables to fetch the pricing when they're row mounts
  // but can't re-render when they receive the new pricing. (more on this in sailings and price cell components)
  if (!cruiseData.pricing && cruiseData.hasUnsupportedQualifiers) {
    // we store unSupportedQualifierPricingData separately in redux to solve the above issue.
    const unSupportedQualifierPricingData = getPackageUnsupportedQualifierPrices(
      state,
      stateKey,
      cruiseData.sailDate,
      cruiseData.id
    )

    if (unSupportedQualifierPricingData) {
      // if the call was successful we'll have .pricing, otherwise
      cruiseData.pricing = unSupportedQualifierPricingData.pricing
        ? unSupportedQualifierPricingData.pricing
        : null
      if (
        unSupportedQualifierPricingData.pricing &&
        unSupportedQualifierPricingData.pricing.isSoldOut
      ) {
        cruiseData.isSoldOut = unSupportedQualifierPricingData.pricing.isSoldOut
      }

      cruiseData.pricingHasError = unSupportedQualifierPricingData.hasError
    } else {
      // this is used by the price cells to make ajax requests to the pricing endpoint.
      cruiseData.mustFetchUnsupportedQualifiers = true
      const { id, sailDate, shipCode, brand } = cruiseData
      cruiseData.unSupportedQualifiersReq = formatRequestForPricing(
        {
          id,
          sailDate,
          shipCode,
          brand,
          agencyId,
          officeCode
        },
        {
          ...(state.activeSearchFilterData || {}),
          countryCode: getAgentCountry(state)
        }
      )
    }
  }

  if (
    cruiseData.pricing &&
    !cruiseData.pricing.isSoldOut &&
    !cruiseData.isSoldOut
  ) {
    cruiseData.mustFetchUnsupportedQualifiers = false
    // format our pricing to be used in table rows

    cruiseData.tabularPricing = getCruiseTabularPricing(
      state,
      cruiseData.pricing
    )
    // cruiseData.selectedStateRooms is an array of selected staterooms
    // each item is an object with the pricing info for the selected guest count pricing
    cruiseData.selectedStateRooms = getPackageSelectedCabins(
      state,
      cruiseIdentifier,
      cruiseData.tabularPricing,
      cruiseData.pricing,
      isLandPackage,
      landPackageIdentifier
    )
  }
  return cruiseData
}

function getPackageSelectedCabins(
  state,
  cruiseIdentifier,
  tabularPricing,
  rawPricing,
  isLandPackage,
  landPackageIdentifier
) {
  const cabinDataKey = isLandPackage ? landPackageIdentifier : cruiseIdentifier
  const selectedPackageData =
    state.activeCabinData && state.activeCabinData[`${cabinDataKey}`]

  const selectedStateRooms = {
    stateRooms: [],
    bestGuestCountRate: null,
    bestPerPersonAveragePrice: null,
    pricingFrom: null
  }
  if (selectedPackageData) {
    Object.entries(selectedPackageData).forEach(
      ([stateRoomKey, stateRoomData]) => {
        if (stateRoomData.isSuperCategory) {
          // we just need to snag the matching bestRateData from our tabular pricing

          if (
            tabularPricing[stateRoomKey] &&
            tabularPricing[stateRoomKey].superCategoryData &&
            tabularPricing[stateRoomKey].superCategoryData.bestGuestCountRate
          ) {
            const superCatData = renderStateRoomFromSuperCategory(
              tabularPricing[stateRoomKey]
            )
            selectedStateRooms.stateRooms.push(superCatData)

            if (
              !selectedStateRooms.bestGuestCountRate ||
              superCatData.netPrice < selectedStateRooms.bestGuestCountRate
            ) {
              selectedStateRooms.bestGuestCountRate = superCatData.netPrice
              selectedStateRooms.bestPerPersonAveragePrice =
                superCatData.perPersonAveragePrice
              selectedStateRooms.pricingFrom = superCatData.pricing
              selectedStateRooms.priceInfo = superCatData.priceInfo
            }
          }
        } else {
          // we need to find this stateRoom in our rawPricing
          // needs to match stateRoomCode and refundability

          const {
            superCategoryCode,
            stateroomType,
            isRefundable
          } = stateRoomData
          if (rawPricing[superCategoryCode].availableStateRooms) {
            const matchingStateRoom = rawPricing[
              superCategoryCode
            ].availableStateRooms.find(room => {
              return (
                room.stateroomCategoryCode === stateroomType &&
                room.isRefundable === isRefundable
              )
            })

            const stateRoomPricing = renderSelectedStateRoomPricing(
              matchingStateRoom.additionalPricing
            )
            selectedStateRooms.stateRooms.push({
              ...matchingStateRoom,
              superCategoryCode,
              pricing: stateRoomPricing
            })

            if (
              !selectedStateRooms.bestGuestCountRate ||
              matchingStateRoom.netPrice < selectedStateRooms.bestGuestCountRate
            ) {
              selectedStateRooms.bestGuestCountRate = matchingStateRoom.netPrice
              selectedStateRooms.bestPerPersonAveragePrice =
                matchingStateRoom.perPersonAveragePrice
              selectedStateRooms.pricingFrom = stateRoomPricing
              selectedStateRooms.priceInfo = matchingStateRoom.priceInfo
            }
          }
        }
      }
    )
  } else {
    // we don't yet have any selected cabins we can just use the
    // superCategoryData.bestGuestCountRate from each superCategory
    Object.values(tabularPricing).forEach(superCategoryData => {
      if (
        superCategoryData &&
        superCategoryData.superCategoryData &&
        superCategoryData.superCategoryData.bestGuestCountRate
      ) {
        const superCatData = renderStateRoomFromSuperCategory(superCategoryData)

        selectedStateRooms.stateRooms.push(superCatData)
        if (
          !selectedStateRooms.bestGuestCountRate ||
          superCatData.netPrice < selectedStateRooms.bestGuestCountRate
        ) {
          selectedStateRooms.bestGuestCountRate = superCatData.netPrice
          selectedStateRooms.bestPerPersonAveragePrice =
            superCatData.perPersonAveragePrice
          selectedStateRooms.pricingFrom = superCatData.pricing
          selectedStateRooms.priceInfo = superCatData.priceInfo
        }
      }
    })
  }
  return selectedStateRooms
}

function renderGuestCountPricingDisplayName(key, additionalPricing) {
  const numberKey = Number(key)
  return numberKey === 1
    ? 'SINGLE'
    : numberKey === 2
    ? '1st/2nd'
    : numberKey === 3
    ? '3rd'
    : additionalPricing[3]
    ? '4th'
    : '3rd/4th'
}

function renderStateRoomFromSuperCategory(superCatData) {
  if (
    superCatData &&
    superCatData.superCategoryData &&
    superCatData.superCategoryData.bestGuestCountRate
  ) {
    const sortedPricing = renderSelectedStateRoomPricing(
      superCatData.superCategoryData.bestGuestCountRate.additionalPricing
    )

    const {
      stateroomType,
      stateroomDescription,
      category
    } = superCatData.superCategoryData
    return {
      categoryName: category.name,
      stateroomType,
      stateroomDescription,
      isSuperCategory: true,
      superCategoryCode: stateroomType,
      ...superCatData.superCategoryData.bestGuestCountRate,
      pricing: sortedPricing,
      category
    }
  }
}

function renderSelectedStateRoomPricing(additionalPricing) {
  const stateRoomPricing = []
  if (additionalPricing) {
    Object.entries(additionalPricing).forEach(([key, value]) => {
      stateRoomPricing.push({
        pricingDisplayName: renderGuestCountPricingDisplayName(
          key,
          additionalPricing
        ),
        ...value,
        order: key
      })
    })
  }

  return stateRoomPricing.sort((a, b) => {
    return a.order - b.order
  })
}

// getAvailableCruises and getAvailableCruiseTours should only return what is needed
// to print the cruise rows on the full search table.
// getPackagesDetails should be called from the children of those tables. The reason is mainly for
// our unsupported qualifier pricing. If the table updates when the pricing comes in our
// table pricing cells remount and make duplicate api call and the table resets it's pagination
// back to page 1.
export const getAvailableCruises = state => {
  return getFullSearchSailingRows(state, 'packagesData')
}

export const getAvailableCruiseTours = state => {
  return getFullSearchSailingRows(state, 'cruiseToursData')
}

const getFullSearchSailingRows = (state, stateKey) => {
  return state[stateKey] &&
    state[stateKey].availablePackages &&
    Object.keys(state[stateKey].availablePackages).length
    ? Object.entries(state[stateKey].availablePackages).reduce(
        (obj, [cruiseIdentifier, cruiseData]) => {
          const {
            id,
            sailDate,
            startDate,
            packageStartDate, // Cruisetours will use this
            brand,
            destinationCode,
            shipCode,
            sailingNights,
            justACruise,
            preTourNights,
            postTourNights,
            description,
            identifier,
            stateKey, // needed to get additional details out of activePackages
            hasLandPackages, // needed to generate landPackages table.
            landPackages,
            stateroomTypes, // needed for N/A vs Sold out text
            totalNights,
            tourTypeLabel
          } = cruiseData
          obj[cruiseIdentifier] = {
            id,
            sailDate,
            startDate,
            packageStartDate,
            brand,
            destinationCode,
            shipCode,
            sailingNights,
            justACruise,
            preTourNights,
            postTourNights,
            description,
            identifier,
            hasLandPackages,
            landPackages,
            stateKey,
            stateroomTypes,
            totalNights,
            tourTypeLabel
          }
          return obj
        },
        {}
      )
    : {}
}
