import React, { Component } from 'react'
import moment from 'moment'
import { get } from 'lodash'
import EQuoteReviewEmailSocial from '../email/EQuoteReviewEmailSocial'

import '../../styles/scss/components/equote-review-email-packages.scss'
import '../../styles/scss/components/equote-land-program-flag.scss'
import formatPrice from '../../utilities/formatPrice'
import landPackageIcon from '../../assets/icons/land-package-icon.svg'
import TaxLine from '../TaxLine'

class EQuoteReviewEmailPackages extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderPackageCards = () => {
    const {
      activePackageData,
      brandData,
      labelsData,
      quoteData,
      activeStepData,
      templateClass,
      terms
    } = this.props

    let renderPackages = []
    for (const [packageKey, currentPackage] of Object.entries(
      activePackageData
    )) {
      const isCruiseTour = !currentPackage.justACruise

      let currentBrand = brandData.filter(
        brand =>
          brand && String(brand.brand_code) === String(currentPackage.brand)
      )

      let reference

      if (quoteData) {
        if (
          activePackageData[packageKey].allSelectedLandPrograms &&
          Object.keys(activePackageData[packageKey].allSelectedLandPrograms)
            .length
        ) {
          // need to match this package with the quoteData containing the same selectedLandPrograms

          const activeSelectedLandPrograms = Object.values(
            activePackageData[packageKey].allSelectedLandPrograms
          ).map(program => program.packageCode)

          const matchingQuoteData = quoteData.data.quote.find(quote => {
            return (
              activeSelectedLandPrograms.length ===
                quote.selectedLandPrograms.length &&
              activeSelectedLandPrograms.every(activeKey =>
                quote.selectedLandPrograms.some(
                  packageKey => activeKey === packageKey
                )
              )
            )
          })

          reference = matchingQuoteData.reference
        } else {
          const correspondingQuoteData = quoteData.data.quote.find(
            quote => quote.id === activePackageData[packageKey].id
          )
          reference = correspondingQuoteData
            ? correspondingQuoteData.reference
            : null
        }
      }

      const imageForSharerLink =
        currentBrand && currentBrand[0] && currentBrand[0].email_image.file
      const captionForSharerLink =
        currentPackage && currentPackage.description
          ? currentPackage.description
          : null
      const linkForSharer = reference
        ? `${process.env.REACT_APP_PACKAGE_ENDPOINT_URL}/?id=${reference}`
        : null

      let bestRate =
        currentPackage &&
        currentPackage.selectedStateRooms &&
        currentPackage.selectedStateRooms.bestPerPersonAveragePrice
          ? formatPrice(
              currentPackage.selectedStateRooms.bestPerPersonAveragePrice
            )
          : null

      const taxes = get(
        currentPackage,
        'selectedStateRooms.priceInfo.taxesFees'
      )
      if (bestRate && currentPackage.allSelectedLandPrograms) {
        const packagesTotalPerPersonPrice = Object.values(
          currentPackage.allSelectedLandPrograms
        ).reduce((num, landPackage) => {
          num = num + Number(landPackage.perGuestPrice)
          return num
        }, 0)

        bestRate = formatPrice(Number(bestRate) + packagesTotalPerPersonPrice)
      }
      const currencySymbol = get(currentPackage, 'currencySymbol', '')
      const currency = get(currentPackage, 'currency', '')
      renderPackages.push(
        <div
          key={packageKey}
          className={`equote__review-email-package ${templateClass}`}
        >
          {Boolean(isCruiseTour && currentPackage.preTourNights) ? (
            <div className="equote__review-email-package--cruisetour">
              <div className="equote__review-email-package--cruisetour-flag">
                <p>Pre-Cruise Package</p>
              </div>
              <p>
                {currentPackage.preTourNights} Night
                {Number(currentPackage.preTourNights) > 1 ? 's' : ''}
              </p>
            </div>
          ) : null}

          {currentPackage.description ? (
            <div className="equote__review-email-package-banner">
              <h6 className="u-remove-margin">
                {currentPackage.cruiseTourPreNights ||
                currentPackage.cruiseTourPostNights
                  ? currentPackage.description
                  : currentPackage.sailingNights
                  ? `${currentPackage.sailingNights} Night ${
                      currentPackage.description
                    }`
                  : currentPackage.description}
              </h6>
            </div>
          ) : null}

          {currentPackage.allSelectedLandPrograms &&
          Object.keys(currentPackage.allSelectedLandPrograms).length
            ? Object.values(currentPackage.allSelectedLandPrograms).map(
                landPackage => (
                  <div
                    key={landPackage.packageCode + landPackage.prePostTagName}
                    className="equote__package-details-outline--land-program"
                  >
                    <div className="equote__package-details-outline--land-program-flag">
                      <div className="equote__package-details-outline--land-program-flag--img-container">
                        <img src={landPackageIcon} alt={landPackage.title} />
                      </div>
                      <p className="u-remove-margin">
                        {landPackage.prePostTagName}-Cruise Land Program
                      </p>
                    </div>
                    <p>{landPackage.title}</p>
                  </div>
                )
              )
            : null}

          <div className="equote__review-email-package-details">
            {bestRate ? (
              <div className="equote__review-email-package-col">
                <div className="equote__review-email-package-col-title">
                  Price From
                </div>
                <div className="equote__review-email-package-col-value best-price">
                  {currencySymbol}
                  {bestRate} {currency}
                  {taxes && (
                    <>
                      <br />
                      <span className="taxes">
                        <i>
                          <TaxLine
                            currencySymbol={currencySymbol}
                            currency={currency}
                            amount={taxes}
                          />
                        </i>
                      </span>
                    </>
                  )}
                </div>
              </div>
            ) : null}

            {currentPackage.shipName ? (
              <div className="equote__review-email-package-col">
                <div className="equote__review-email-package-col-title">
                  Ship Name
                </div>
                <div className="equote__review-email-package-col-value">
                  {currentPackage.shipName}
                </div>
              </div>
            ) : null}

            {currentPackage.destinationName ? (
              <div className="equote__review-email-package-col">
                <div className="equote__review-email-package-col-title">
                  Destination
                </div>
                <div className="equote__review-email-package-col-value">
                  {currentPackage.destinationName}
                </div>
              </div>
            ) : null}

            {currentPackage.sailDate ? (
              <div className="equote__review-email-package-col">
                <div className="equote__review-email-package-col-title">
                  Sail Date
                </div>
                <div className="equote__review-email-package-col-value">
                  {moment(currentPackage.sailDate).format('MMMM DD, YYYY')}
                </div>
              </div>
            ) : null}
          </div>
          {Boolean(isCruiseTour && currentPackage.postTourNights) ? (
            <div className="equote__review-email-package--cruisetour">
              <div className="equote__review-email-package--cruisetour-flag">
                <p>Post-Cruise Package </p>
              </div>
              <p>
                {currentPackage.postTourNights} Night
                {Number(currentPackage.postTourNights) > 1 ? 's' : ''}
              </p>
            </div>
          ) : null}
          <div className="equote__review-email-package-actions">
            <button
              className="btn equote__review-email-package-btn"
              onClick={() => {
                if (reference) {
                  window.open(
                    `${
                      process.env.REACT_APP_PACKAGE_ENDPOINT_URL
                    }/?id=${reference}`,
                    '_blank'
                  )
                }
              }}
            >
              {labelsData &&
              labelsData.email &&
              labelsData.email.preview_details_cta
                ? labelsData.email.preview_details_cta
                : 'View Full Details'}
            </button>
          </div>

          <EQuoteReviewEmailSocial
            brandData={currentBrand}
            imageForSharerLink={imageForSharerLink}
            captionForSharerLink={captionForSharerLink}
            linkForSharer={linkForSharer}
            templateClass={templateClass}
          />
          {activeStepData &&
            activeStepData.activeStep === 4 &&
            terms &&
            terms.terms_content && (
              <p
                className="equote__review-email-package-terms"
                dangerouslySetInnerHTML={{ __html: terms.terms_content }}
              />
            )}
        </div>
      )
    }

    return renderPackages
  }

  render() {
    return (
      <div className="equote__review-email-packages">
        {this.renderPackageCards()}
      </div>
    )
  }
}

export default EQuoteReviewEmailPackages
