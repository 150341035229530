import React, { Component } from 'react'
import { connect } from 'react-redux'

import EQuoteConfirmQuoteHeader from '../../components/confirm/EQuoteConfirmQuoteHeader'
import EQuoteReviewEmailPackages from '../../components/email/EQuoteReviewEmailPackages'
import EQuoteReviewEmailHeader from '../../components/email/EQuoteReviewEmailHeader'
import EQuoteReviewEmailHero from '../../components/email/EQuoteReviewEmailHero'

import {
  resetActiveCabins,
  resetActivePackages,
  resetActiveFilters,
  resetActiveEmailConfiguration
} from '../../actions/resetQuoteFlow'
import setActiveStep from '../../actions/setActiveStepActions'
import updateSearchParams from '../../utilities/updateSearchParams'
import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'
import { getBrandsData } from '../../sessionStorage/getBrandsData'
import '../../styles/scss/components/equote-confirm-quote.scss'
import { getAllActivePackagesDetails } from '../../reducers/setActivePackageReducers'
import { getAllActiveCabinData } from '../../reducers/setActiveCabinReducers'
import { getDestinationsData } from '../../sessionStorage/getDestinationsData'
import { getLabelsData } from '../../sessionStorage/getLabelsData'
import getShipImageByCode from '../../utilities/getShipImageByCode'
import { getShipsData } from '../../sessionStorage/getShipsData'
import EQuoteReviewEmailFooter from '../../components/email/EQuoteReviewEmailFooter'
import { removeAllLoyalty } from '../../actions/removeActiveLoyalty'
import { getTermsByCurrency } from '../../reducers/fetchConfigurationReducers'

class EQuoteConfirmQuote extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    // Dispatch GA event for search component
    const trackingComponentData = {
      currentStep: 5,
      component: 'Quote Success'
    }

    triggerCustomGaEvent(
      'equoteTracking-currentActiveStep',
      trackingComponentData
    )
  }

  handleResetQuoteFlow() {
    const { activeSearchFilterData, resetQuoteFlow } = this.props
    let resetQuery = {
      sso: activeSearchFilterData.sso
    }

    resetQuoteFlow()
    updateSearchParams(resetQuery)
    triggerCustomGaEvent('equoteTracking-beginNewQuote', {
      component: 'EQuoteConfirmQuote'
    })
  }

  render() {
    const {
      activePackageData,
      activeSearchFilterData,
      brands,
      destinationsData,
      labelsData,
      recipients,
      quoteData,
      activeCabinData,
      activeStepData,
      terms,
      ships
    } = this.props

    const totalPackages = Object.keys(activePackageData).length
    const selectedShip = Object.values(activePackageData).length === 1 && ships.find(ship => ship.ship_code === Object.values(activePackageData)[0].shipCode)
    const selectedShipCode = selectedShip && selectedShip.ship_code
    const selectedShipImage = selectedShip && getShipImageByCode(selectedShipCode)

    let brandData = [],
      templateClass = 'brand-multi',
      brandsSelected = []

      for (const activePackage of Object.values(activePackageData)) {
        brandsSelected.push(
          brands.find(
            brand =>
              brand && String(brand.brand_code) === String(activePackage.brand)
          )
        )

        if (!brandData.some(brand => brand.brand_code === activePackage.brand)) {
          brandData.push(
            brands.find(brand => brand.brand_code === activePackage.brand)
          )
        }
      }
      if (
        brandData.some(brand => brand.brand_code === 'C') &&
        !brandData.some(brand => brand.brand_code === 'R')
      ) {
        templateClass = 'brand-c'
      } else if (
        brandData.some(brand => brand.brand_code === 'R') &&
        !brandData.some(brand => brand.brand_code === 'C')
      ) {
        templateClass = 'brand-r'
      }
    const brandTotal = brandData.length

    return (
      <div className={`equote__confirm-quote ${templateClass}`}>
        <EQuoteConfirmQuoteHeader recipients={recipients} />

        <h3>{totalPackages > 1 ? 'Cruises Sent' : 'Cruise Sent'}</h3>
        <div className="equote__confirm-quote--content-container">
          <div className="equote__confirm-quote--hero">
            <EQuoteReviewEmailHeader
              brandData={brandData}
              brandTotal={brandTotal}
            />
            {brandData &&
            brandData[0] &&
            brandData[0].image &&
            brandData[0].image[0] &&
            brandData[0].image[0].file ? (
              <EQuoteReviewEmailHero
                brandData={brandData}
                brandTotal={brandTotal}
                brands={brands}
                selectedShipImage={selectedShipImage}
              />
            ) : null}
          </div>
          <EQuoteReviewEmailPackages
            activePackageData={activePackageData}
            activeCabinData={activeCabinData}
            brandData={brandData}
            destinationsData={destinationsData}
            labelsData={labelsData}
            quoteData={quoteData}
            activeSearchFilterData={activeSearchFilterData}
            activeStepData={activeStepData}
            terms={terms}
            templateClass={templateClass}
          />
          <EQuoteReviewEmailFooter templateClass={templateClass} brandsSelected={brandsSelected} />
        </div>

        <div className="equote__confirm-quote-actions">
          <button
            className="btn btn-primary"
            onClick={() => this.handleResetQuoteFlow()}
          >
            Start a New Quote
          </button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    activeSearchFilterData: state.activeSearchFilterData,
    activePackageData: getAllActivePackagesDetails(state),
    brands: getBrandsData(),
    destinationsData: getDestinationsData(),
    labelsData: getLabelsData(),
    recipients: state.activeEmailConfigurationData.recipients,
    quoteData: state.quoteData.quote ? state.quoteData.quote : null,
    activeCabinData: getAllActiveCabinData(state.activeCabinData),
    activeStepData: state.activeStepData,
    terms: getTermsByCurrency(state),
    ships: getShipsData()
  }
}

const mapDispatchToProps = dispatch => ({
  resetQuoteFlow() {
    dispatch(resetActiveCabins())
    dispatch(resetActivePackages())
    dispatch(resetActiveFilters())
    dispatch(resetActiveEmailConfiguration())
    dispatch(removeAllLoyalty())
    dispatch(setActiveStep(0))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteConfirmQuote)
