import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'

import EQuoteReviewEmailHeader from '../email/EQuoteReviewEmailHeader'
import EQuoteReviewEmailHero from '../email/EQuoteReviewEmailHero'
import EQuoteReviewEmailBasicContent from '../email/EQuoteReviewEmailBasicContent'
import EQuoteReviewEmailPackages from '../email/EQuoteReviewEmailPackages'
import EQuoteReviewEmailAgentBanner from '../email/EQuoteReviewEmailAgentBanner'

import previewEmailIcon from '../../assets/icons/preview-email.svg'

import '../../styles/scss/components/equote-review-construct-email.scss'
import { getAllActiveCabinData } from '../../reducers/setActiveCabinReducers'
import { getBrandsData } from '../../sessionStorage/getBrandsData'
import { getShipsData } from '../../sessionStorage/getShipsData'
import getShipImageByCode from '../../utilities/getShipImageByCode'
import EQuoteReviewEmailFooter from '../email/EQuoteReviewEmailFooter'

class EQuoteReviewConstructEmail extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      activeEmailConfigurationData,
      activePackageData,
      brands,
      destinationsData,
      labelsData,
      modifiedSsoData,
      ssoData,
      activeSearchFilterData,
      activeCabinData,
      activeStepData,
      ships
    } = this.props

    const selectedShip =
      Object.values(activePackageData).length === 1 &&
      ships.find(ship => ship.ship_code === Object.values(activePackageData)[0].shipCode)
    const selectedShipCode = selectedShip.ship_code
    const selectedShipImage = getShipImageByCode(selectedShipCode || '')
    let brandData = [],
      templateClass = 'brand-multi',
      brandsSelected = []
    for (const activePackage of Object.values(activePackageData)) {
      brandsSelected.push(
        brands.find(
          brand =>
            brand && String(brand.brand_code) === String(activePackage.brand)
        )
      )

      if (!brandData.some(brand => brand.brand_code === activePackage.brand)) {
        brandData.push(
          brands.find(brand => brand.brand_code === activePackage.brand)
        )
      }
    }
    if (
      brandData.some(brand => brand.brand_code === 'C') &&
      !brandData.some(brand => brand.brand_code === 'R')
    ) {
      templateClass = 'brand-c'
    } else if (
      brandData.some(brand => brand.brand_code === 'R') &&
      !brandData.some(brand => brand.brand_code === 'C')
    ) {
      templateClass = 'brand-r'
    }

    const brandTotal = brandData.length
    return (
      <Fragment>
        <div className="equote__review-construct-email-header">
          <div className="equote__review-construct-email-header-img">
            <img src={previewEmailIcon} alt="Ship icon" />
          </div>
          <h6 className="u-remove-margin">Email Preview</h6>
        </div>

        <div className={'equote__review-construct-email ' + templateClass}>
          <div className="equote__review-construct-email--content-container">
            <EQuoteReviewEmailHeader
              brandData={brandData}
              brandTotal={brandTotal}
            />

            {brandData &&
            brandData[0] &&
            brandData[0].image &&
            brandData[0].image[0] &&
            brandData[0].image[0].file ? (
              <EQuoteReviewEmailHero
                brandData={brandData}
                brandTotal={brandTotal}
                brands={brands}
                selectedShipImage={selectedShipImage}
              />
            ) : null}

            <EQuoteReviewEmailBasicContent
              activeEmailConfigurationData={activeEmailConfigurationData}
              labelsData={labelsData}
            />
          </div>

          {activePackageData ? (
            <EQuoteReviewEmailPackages
              activePackageData={activePackageData}
              brandData={brandData}
              destinationsData={destinationsData}
              labelsData={labelsData}
              activeCabinData={activeCabinData}
              activeSearchFilterData={activeSearchFilterData}
              activeStepData={activeStepData}
              templateClass={templateClass}
            />
          ) : null}

          {ssoData ? (
            <EQuoteReviewEmailAgentBanner
              modifiedSsoData={modifiedSsoData}
              ssoData={ssoData}
            />
          ) : null}
        </div>
        <EQuoteReviewEmailFooter templateClass={templateClass} brandsSelected={brandsSelected}/>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    brands: getBrandsData(),
    modifiedSsoData: state.modifiedSsoData,
    activeSearchFilterData: state.activeSearchFilterData,
    activeCabinData: getAllActiveCabinData(state),
    activeStepData: state.activeStepData,
    ships: getShipsData()
  }
}

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteReviewConstructEmail)
