export default function(reqParams, activeSearchFilters) {
  const { id, sailDate, shipCode, brand } = reqParams
  const agencyId = reqParams.agencyId || ''
  const officeCode = reqParams.officeCode || ''
  const {
    isPolice,
    isServiceMember,
    isFireFighter,
    isSeniorCitizen,
    residentOf,
    loyaltyId,
    currency,
    guestCount,
    loyaltyTier = '',
    countryCode
  } = activeSearchFilters
  const urlParams = {
    id,
    brand,
    shipCode,
    sailDate,
    currencyCode: currency,
    seniorReqd: isSeniorCitizen,
    militaryReqd: isServiceMember,
    policeReqd: isPolice,
    fireFighterReqd: isFireFighter,
    loyaltyId,
    loyaltyTierCode: loyaltyId ? loyaltyTier : '',
    stateCode: residentOf,
    occupancy: guestCount,
    countryCode,
    agencyId,
    officeCode
  }

  // Fetch Query string method
  const fetchQuery = new URLSearchParams()

  // // Loop urlParams for undefined values & remove
  for (let key in urlParams) {
    if (
      typeof urlParams[key] === 'undefined' ||
      urlParams[key] === false ||
      key === 'id'
    )
      continue
    fetchQuery.append(key, urlParams[key])
  }

  // Base API path
  return `${
    process.env.REACT_APP_FLOW_ENDPOINT_URL
  }cruise/${id}/vps-pricing?${fetchQuery.toString()}`
}
