import _, { get } from 'lodash'

import {
  FETCH_SSO_BEGIN,
  FETCH_SSO_SUCCESS,
  FETCH_SSO_FAILURE
} from '../actions/fetchSso'
import { bundlePricingCountryCodes } from '../configuration/currencies'

const initialState = {
  loading: false,
  error: null
}

export default function fetchSsoReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_SSO_BEGIN:
      // Request begin: set loading true
      return {
        ...state,
        loading: true,
        error: null
      }

    case FETCH_SSO_SUCCESS:
      // Request success: set loading false, and display request data
      return {
        ...state,
        loading: false,
        ...action.payload
      }

    case FETCH_SSO_FAILURE:
      // Request failed: set loading false, save & display error
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        ...[]
      }

    default:
      return state
  }
}

export const multipleCurrenciesAvailable = state => {
  const { ssoData } = state

  return Boolean(
    ssoData &&
      ssoData.transactionData &&
      !_.isEmpty(ssoData.transactionData.paymentLevels) &&
      ssoData.transactionData.paymentLevels.length > 1
  )
}
const promoEnabledCountryCodes = ['USA', 'CAN']

export const getCanUseQualifiers = state => {
  const agentCountry = get(state, 'ssoData.agent.country', '').toUpperCase()
  return (
    agentCountry && promoEnabledCountryCodes.some(code => code === agentCountry)
  )
}

export const getAgentCountry = state => get(state, 'ssoData.agent.country', '')

export const getIsBundlePricing = state => {
  const countryCode = getAgentCountry(state)
  return bundlePricingCountryCodes.some(code => code === countryCode)
}

export const getDisabledBrandsFromSearchCriteria = state => {
  const unsupportedBrandsCombinations = get(
    state,
    'ssoData.unsupportedBrandsCombinations',
    []
  )
  const countryCode = getAgentCountry(state)
  return function({ currency }) {
    return unsupportedBrandsCombinations
      .filter(
        combination =>
          combination.currencyCode === currency &&
          combination.countryCode === countryCode
      )
      .map(combo => combo.brandCode)
  }
}
