import axios from 'axios'
import moment from 'moment'
import 'url-search-params-polyfill'
import setActiveStep from './setActiveStepActions'
import triggerCustomGaEvent from '../utilities/triggerCustomGaEvent'
import cleanObject from '../utilities/cleanObject'
import { getAllActivePackagesDetails } from '../reducers/setActivePackageReducers'
import { getAgentCountry } from '../reducers/fetchSsoReducers'

var _ = require('lodash')

export const SUBMIT_QUOTE_BEGIN = 'SUBMIT_QUOTE_BEGIN'
export const SUBMIT_QUOTE_SUCCESS = 'SUBMIT_QUOTE_SUCCESS'
export const SUBMIT_QUOTE_FAILURE = 'SUBMIT_QUOTE_FAILURE'

export const submitQuoteBegin = () => ({
  type: SUBMIT_QUOTE_BEGIN
})

export const submitQuoteSuccess = quote => ({
  type: SUBMIT_QUOTE_SUCCESS,
  payload: quote
})

export const submitQuoteError = error => ({
  type: SUBMIT_QUOTE_FAILURE,
  payload: { error }
})

// Handle Brands Fetch & Functionality
export default function submitQuote() {
  return (dispatch, getState) => {
    const state = getState()
    const packagesData = getAllActivePackagesDetails(state),
      renderQuotePackages = [],
      currentBrandsData = []

    if (packagesData && Object.keys(packagesData).length) {
      for (const currentPackage of Object.values(packagesData)) {
        const applicableCabins =
          currentPackage.selectedStateRooms &&
          currentPackage.selectedStateRooms.stateRooms
            ? currentPackage.selectedStateRooms.stateRooms.map(stateRoom => {
                return {
                  superCategoryCode: stateRoom.superCategoryCode,
                  packageId: currentPackage.id,
                  sailDate: currentPackage.sailDate,
                  ship: currentPackage.ship,
                  fareCode: stateRoom.selectedFareCode,
                  category: stateRoom.stateroomCategoryCode,
                  categoryName: stateRoom.stateroomCategoryName,
                  refundable: stateRoom.isRefundable,
                  selected: true,
                  isSuperCategory: stateRoom.isSuperCategory
                }
              })
            : []

        // Build array of current package brands
        if (currentPackage && currentPackage.brand) {
          currentBrandsData.push(currentPackage.brand)
        }

        const cabinLowestPrice =
          currentPackage.selectedStateRooms &&
          currentPackage.selectedStateRooms.bestGuestCountRate
            ? {
                bestRate: currentPackage.selectedStateRooms.bestGuestCountRate,
                pricingFrom: currentPackage.selectedStateRooms.pricingFrom
              }
            : null

        const selectedLandPrograms =
          currentPackage.allSelectedLandPrograms &&
          Object.keys(currentPackage.allSelectedLandPrograms)
            ? Object.values(currentPackage.allSelectedLandPrograms).map(
                landProgram => landProgram.packageCode
              )
            : null

        // Create package entry with active cabins

        /* while Cruisetours have a sailDate and startDate set.
         * land programs do not, and they can be added individually
         * the /package app requires the startDate so we'll override it here.
         * */
        let startDate = currentPackage.startDate
        if (
          (currentPackage.isLandPackage || currentPackage.hasLandPackages) &&
          currentPackage.allSelectedLandPrograms
        ) {
          Object.values(currentPackage.allSelectedLandPrograms).forEach(
            program => {
              if (
                program.tourStartDate &&
                moment(program.tourStartDate, 'YYYY-MM-DD').isBefore(
                  moment(startDate, 'YYYY-MM-DD'),
                  'day'
                )
              ) {
                startDate = program.tourStartDate
              }
            }
          )
        }
        const renderQuotePackage = {
          id: currentPackage.id,
          brand: currentPackage.brand,
          description: currentPackage.description,
          destinationCode: currentPackage.destinationCode,
          destinationName: currentPackage.destinationName,
          lowestRate: cabinLowestPrice,
          ports: currentPackage.cruisePort,
          sailDate: currentPackage.sailDate,
          startDate: startDate,
          shipCode: currentPackage.shipCode,
          shipName: currentPackage.shipName,
          selectedCabins: applicableCabins,
          selectedLandPrograms,
          sailingNights: currentPackage.sailingNights,
          loyaltyId: state.loyaltyData.loyalty
        }
        renderQuotePackages.push(renderQuotePackage)
      }

      const ssoData = state.ssoData,
        modifiedSsoData = state.modifiedSsoData

      let agentId = null,
        agentName = null,
        agentEmail = null,
        agentPhone = null,
        agentAddress1 = null,
        agentAddress2 = null,
        agentCity = null,
        agentStateProv = null,
        agentCountry = null,
        agentZipPostal = null,
        agencyId = null,
        agencyName = null,
        agencyEmail = null,
        agencyPhone = null,
        agencyWebsite = null,
        agencyLogo = null

      // Set Agent data edit VIA edit agent response, content hub or ESL
      if (ssoData && ssoData.agent && ssoData.agent.rccl_id) {
        agentId = ssoData.agent.rccl_id
      }

      if (
        modifiedSsoData &&
        modifiedSsoData.agent &&
        modifiedSsoData.agent.name
      ) {
        agentName = modifiedSsoData.agent.name
      } else if (ssoData && ssoData.agent && ssoData.agent.name) {
        agentName = ssoData.agent.name
      }

      if (
        modifiedSsoData &&
        modifiedSsoData.agent &&
        modifiedSsoData.agent.email
      ) {
        agentEmail = modifiedSsoData.agent.email
      } else if (ssoData && ssoData.agent && ssoData.agent.email) {
        agentEmail = ssoData.agent.email
      }

      if (
        modifiedSsoData &&
        modifiedSsoData.agent &&
        modifiedSsoData.agent.phone
      ) {
        agentPhone = modifiedSsoData.agent.phone
      } else if (ssoData && ssoData.agent && ssoData.agent.phone) {
        agentPhone = ssoData.agent.phone
      }

      if (ssoData && ssoData.agent && ssoData.agent.address1) {
        agentAddress1 = ssoData.agent.address1
      }

      if (ssoData && ssoData.agent && ssoData.agent.address2) {
        agentAddress2 = ssoData.agent.address2
      }

      if (ssoData && ssoData.agent && ssoData.agent.city) {
        agentCity = ssoData.agent.city
      }

      if (ssoData && ssoData.agent && ssoData.agent.stateProv) {
        agentStateProv = ssoData.agent.stateProv
      }

      if (ssoData && ssoData.agent && ssoData.agent.country) {
        agentCountry = ssoData.agent.country
      }

      if (ssoData && ssoData.agent && ssoData.agent.zipPostal) {
        agentZipPostal = ssoData.agent.zipPostal
      }

      // Set Agency data edit VIA edit agent response, content hub or ESL
      if (ssoData && ssoData.agency && ssoData.agency.agency_id) {
        agencyId = ssoData.agency.agency_id
      }

      if (
        modifiedSsoData &&
        modifiedSsoData.agency &&
        modifiedSsoData.agency.name
      ) {
        agencyName = modifiedSsoData.agency.name
      } else if (ssoData && ssoData.agency && ssoData.agency.name) {
        agencyName = ssoData.agency.name
      }

      if (
        modifiedSsoData &&
        modifiedSsoData.agency &&
        modifiedSsoData.agency.email
      ) {
        agencyEmail = modifiedSsoData.agency.email
      } else if (ssoData && ssoData.agency && ssoData.agency.email) {
        agencyEmail = ssoData.agency.email
      }

      if (
        modifiedSsoData &&
        modifiedSsoData.agency &&
        modifiedSsoData.agency.phone
      ) {
        agencyPhone = modifiedSsoData.agency.phone
      } else if (ssoData && ssoData.agency && ssoData.agency.phone) {
        agencyPhone = ssoData.agency.phone
      }

      if (
        modifiedSsoData &&
        modifiedSsoData.agency &&
        modifiedSsoData.agency.website
      ) {
        agencyWebsite = modifiedSsoData.agency.website
      } else if (ssoData && ssoData.agency && ssoData.agency.website) {
        agencyWebsite = ssoData.agency.website
      }

      if (
        modifiedSsoData &&
        modifiedSsoData.agency &&
        modifiedSsoData.agency.logo
      ) {
        agencyLogo = modifiedSsoData.agency.logo
      } else if (ssoData && ssoData.agency && ssoData.agency.logo) {
        agencyLogo = ssoData.agency.logo
      }

      const agentData = {
        rccl_id: agentId,
        name: agentName,
        phone: agentPhone,
        address1: agentAddress1,
        address2: agentAddress2,
        city: agentCity,
        stateProv: agentStateProv,
        country: agentCountry,
        zipPostal: agentZipPostal,
        email: agentEmail
      }

      const agencyData = {
        agency_id: agencyId,
        name: agencyName,
        email: agencyEmail,
        phone: agencyPhone,
        website: agencyWebsite,
        logo: agencyLogo
      }

      const parameterData = {
        brand: _.uniq(currentBrandsData).toString(),
        bookingType: state.activeSearchFilterData.bookingType,
        cruiseType: state.activeSearchFilterData.cruiseType,
        currency: state.activeSearchFilterData.currency,
        startDate: state.activeSearchFilterData.startDate,
        endDate: state.activeSearchFilterData.endDate,
        count: state.activeSearchFilterData.count,
        duration: state.activeSearchFilterData.duration,
        ship: state.activeSearchFilterData.ship,
        port: state.activeSearchFilterData.port,
        region: state.activeSearchFilterData.region,
        seniorReqd: state.activeSearchFilterData.isSeniorCitizen,
        militaryReqd: state.activeSearchFilterData.isServiceMember,
        policeReqd: state.activeSearchFilterData.isPolice,
        fireFighterReqd: state.activeSearchFilterData.isFireFighter,
        loyaltyId: state.activeSearchFilterData.loyaltyId,
        loyaltyTierCode: state.activeSearchFilterData.loyaltyId
          ? state.activeSearchFilterData.loyaltyTier
          : '',
        stateCode: state.activeSearchFilterData.residentOf,
        sortBy: state.activeSearchFilterData.sortBy,
        occupancy: state.activeSearchFilterData.guestCount,
        countryCode: getAgentCountry(state)
      }

      const emailData = state.activeEmailConfigurationData

      if (emailData.sendCopyToAgent && agentEmail) {
        emailData.recipients.push(agentEmail)
      }

      const quoteData = {
        createParameters: {
          agent: agentData,
          agency: agencyData,
          email: emailData,
          parameters: cleanObject(parameterData),
          quote: renderQuotePackages
        }
      }
      // Formatted API path
      const quoteApi = process.env.REACT_APP_FLOW_ENDPOINT_URL + 'quote'

      dispatch(submitQuoteBegin())

      axios
        .post(quoteApi, JSON.stringify(quoteData), {
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/ld+json'
          }
        })
        .then(response => {
          const quote = response

          if (quote) {
            dispatch(submitQuoteSuccess(quote))

            // Dispatch GA event for submit quote
            triggerCustomGaEvent('equoteTracking-submitQuote', {
              quote,
              quoteData
            })

            const referrer = _.get(state, 'configurationData.referrer')
            if (referrer === 'shipLocator') {
              const referrerData = _.get(
                state,
                'configurationData.referrerData',
                {}
              )
              const activePackages = Object.values(packagesData || {})

              const matchingPackage = activePackages.find(
                sailing => sailing.id === referrerData.packageCode
              )

              if (matchingPackage) {
                triggerCustomGaEvent('ShipLocatorSendEmailButton', {
                  ssoData,
                  matchingPackage
                })
              }
            }
          }

          if (quote.status === 200 || quote.status === 201) {
            dispatch(setActiveStep(state.activeStepData.activeStep + 1))
          }
        })
        .catch(error => {
          dispatch(submitQuoteError(error))
        })
    }
  }
}
