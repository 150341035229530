export const nonStandardCurrencySymbols = {
  SGD: 'S$',
  HKD: 'HK$'
}

export const currencyNames = {
  USD: 'US Dollars',
  CAD: 'Canadian Dollars',
  SGD: 'Singapore Dollars',
  HKD: 'Hong Kong Dollars'
}

export const bundlePricingCountryCodes = ['USA']
