import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import EQuotePackageSocialMedia from './EQuotePackageSocialMedia'
import { filterImagesByFileName } from '../../utilities/filterImagesByFileName'

import '../../styles/scss/components/equote-package-details-outline.scss'

import formatPrice from '../../utilities/formatPrice'
import landPackageIcon from '../../assets/icons/land-package-icon.svg'
import TaxLine from '../TaxLine'

const EQuotePackageDetailsOutline = props => {
  const {
    packageData,
    brandDetails,
    selectedStateRoomData,
    landProgramData
    // guestCount
  } = props
  const currencySymbol = get(packageData, 'currencySymbol', '$')
  const currency = get(packageData, 'currency', '')
  const brandCode = packageData.brand
  const hasTourNights = packageData && !packageData.justACruise

  const brandImage = filterImagesByFileName(
    [brandDetails['badge_dark']],
    'details-hero'
  )[0].displayImage

  const displayDate =
    landProgramData && landProgramData.pre
      ? moment(landProgramData.pre.tourStartDate, 'YYYY-MM-DD').format(
          'MMMM DD, YYYY'
        )
      : moment(packageData.startDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')

  const taxTotal = get(selectedStateRoomData, 'priceInfo.taxesFees', null)
  const displayTax = taxTotal ? Number(taxTotal).toFixed(2) : null

  let landProgramPrices
  if (landProgramData) {
    landProgramPrices = Object.values(landProgramData).reduce(
      (total, program) => {
        total += Number(program.perGuestPrice)
        return total
      },
      0
    )
  }
  return (
    <div id="mid-width-alt" className="equote__package-details-outline">
      <div className="equote__package-details-outline--title">
        {brandCode && (
          <div className="equote__package-details-outline--title-logo">
            <img src={brandImage} alt={brandDetails.name} />
          </div>
        )}
        {packageData && packageData.description && !hasTourNights && (
          <p>
            {packageData.sailingNights
              ? `${packageData.sailingNights} Night ${packageData.description}`
              : packageData.description}
          </p>
        )}
      </div>
      {hasTourNights && (
        <div className="equote__package-details-outline-tour-details">
          {Boolean(packageData.preTourNights) && (
            <div className="equote__package-details-outline-tour-details--pre-cruise">
              <div className="equote__package-details-outline-tour-details--pre-cruise-flag">
                <p>Pre-Cruise Package</p>
              </div>
              <p>
                {packageData.preTourName ||
                  `${packageData.preTourNights} ${
                    packageData.preTourNights === 1 ? 'Night' : 'Nights'
                  }`}
              </p>
            </div>
          )}
          <p className="equote__package-details-outline-tour-details--primary-title">
            {packageData && packageData.description}
          </p>
        </div>
      )}

      <div className="equote__package-details-outline--table">
        <div className={`equote__package-details-outline--table-cell`}>
          <div
            className={`equote__package-details-outline--table-cell--heading`}
          >
            SAILING FROM
          </div>
          <div className="equote__package-details-outline--table-cell--info">
            {packageData.cruisePort &&
              packageData.cruisePort.length &&
              packageData.cruisePort[0].description}
          </div>
        </div>
        <div className={`equote__package-details-outline--table-cell`}>
          <div
            className={`equote__package-details-outline--table-cell--heading`}
          >
            SHIP
          </div>
          <div className="equote__package-details-outline--table-cell--info">
            {packageData.shipName && packageData.shipName}
          </div>
        </div>
        <div className={`equote__package-details-outline--table-cell`}>
          <div
            className={`equote__package-details-outline--table-cell--heading`}
          >
            DEPARTURE DATE
          </div>
          <div className="equote__package-details-outline--table-cell--info">
            {packageData && displayDate}
          </div>
        </div>
        <div className={`equote__package-details-outline--table-cell`}>
          <div className="equote__package-details-outline--table-cell--heading">
            PRICING FROM
          </div>

          <div className="equote__package-details-outline--table-cell--info pricing-info-cell">
            {selectedStateRoomData && selectedStateRoomData.pricingFrom ? (
              <div className="equote__package-details-outline--table-cell--info--pricing">
                {selectedStateRoomData.pricingFrom.map((price, i) => {
                  return (
                    <div
                      key={i + price.pricingDisplayName}
                      className="equote__package-details-outline--table-cell--info--pricing-cell"
                    >
                      <div className="equote__package-details-outline--table-cell--info--pricing-cell--guest-count">
                        {price.pricingDisplayName}
                      </div>
                      <span className="equote__package-details-outline--table-cell--info--pricing-cell--price">
                        {currencySymbol}
                        {formatPrice(
                          landProgramPrices
                            ? Number(price.displayPrice) +
                                Number(landProgramPrices)
                            : price.displayPrice
                        )}{' '}
                        {currency}
                      </span>
                      {/* Leaving this in case it comes back */}
                      {/*{price.displayLeadPromoDesc && (*/}
                      {/*  <span className="equote__package-details-outline--table-cell--info--pricing-cell--promo">*/}
                      {/*    {price.displayLeadPromoDesc}*/}
                      {/*  </span>*/}
                      {/*)}*/}

                      {Boolean(
                        price.displayCreditAmt &&
                          Number(price.displayCreditAmt) > 0
                      ) && (
                        <span className="equote__package-details-outline--table-cell--info--pricing-cell--obc">
                          {currencySymbol}
                          {price.displayCreditAmt.toFixed(2)} OBC
                        </span>
                      )}
                    </div>
                  )
                })}
              </div>
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>
      {hasTourNights && (
        <div className="equote__package-details-outline-tour-details">
          {Boolean(packageData.postTourNights) && (
            <div className="equote__package-details-outline-tour-details--post-cruise">
              <div className="equote__package-details-outline-tour-details--post-cruise-flag">
                <p>Post-Cruise Package</p>
              </div>
              <p>
                {packageData.postTourName ||
                  `${packageData.postTourNights} ${
                    packageData.postTourNights === 1 ? 'Night' : 'Nights'
                  }`}
              </p>
            </div>
          )}
        </div>
      )}
      {Boolean(displayTax) && (
        <div className="equote__package-details-outline-fees">
          <p>
            <i>
              <TaxLine
                amount={displayTax}
                currencySymbol={currencySymbol}
                currency={currency ? currency : ''}
              />
            </i>
          </p>
        </div>
      )}
      {landProgramData && Object.keys(landProgramData).length
        ? Object.values(landProgramData).map(landProgramData => {
            return (
              <div
                className="equote__package-details-outline--land-program"
                key={landProgramData.packageCode + landProgramData.title}
              >
                <div className="equote__package-details-outline--land-program-flag">
                  <div className="equote__package-details-outline--land-program-flag--img-container">
                    <img src={landPackageIcon} alt="Cruise Land Package" />
                  </div>
                  <p className="u-remove-margin">{`${
                    landProgramData.prePostTagName
                  }-Cruise Land Program`}</p>
                </div>
                <div className="equote-preview-package-outline--land-program-title">
                  <p className="u-remove-margin">{landProgramData.title}</p>
                </div>
              </div>
            )
          })
        : null}
      <div className="equote__package-details-outline-share">
        <EQuotePackageSocialMedia brandCode={brandCode} />
      </div>
    </div>
  )
}

export default EQuotePackageDetailsOutline
