import axios from 'axios'
import 'url-search-params-polyfill'
import moment from 'moment'
import { get } from 'lodash'
import { nonStandardCurrencySymbols } from '../configuration/currencies'
import formatRequestForPricing from '../utilities/formatRequestForPricing'
import { formatGuestCountPricing } from '../utilities/formatPackages'
import { getAgentCountry } from '../reducers/fetchSsoReducers'
export const RECEIVE_RELATED_SAILINGS = 'RECEIVE_RELATED_SAILINGS'
export const RELATED_SAILINGS_ERROR = 'RELATED_SAILINGS_ERROR'
export const REQUEST_RELATED_SAILINGS = 'REQUEST_RELATED_SAILINGS'
export const FINISHED_RELATED_SAILINGS_REQUESTS =
  'FINISHED_RELATED_SAILINGS_REQUESTS'

const sailDateFormat = 'YYYY-MM-DD'

export const fetchRelatedSailings = relatedSailingData => {
  return async (dispatch, getState) => {
    dispatch({
      type: REQUEST_RELATED_SAILINGS
    })

    /*
     * we need both details and pricing for each related sailing
     * /api/cruise/FL07G016/pricing?brand=C&countryCode=USA&shipCode=FL&sailDate=2019-12-08
     * /api/cruise/FL15G016?brand=C
     *
     * if available we need one sailing prior and the next four sailings
     *
     * */

    const {
      relatedSailings,
      sailDate,
      shipCode,
      brand,
      packageKey,
      stateroomTypes
    } = relatedSailingData
    let priorRelateSailing
    const nextSailings = []
    // Filter our related sailings
    for (const sailing of relatedSailings) {
      // we need to filter out duplicate days in our related sailing as they don't return any data at the
      // time of this comment
      if (
        relatedSailings.some(
          otherSailing =>
            otherSailing.sailDate === sailing.sailDate &&
            otherSailing.id !== sailing.id
        )
      )
        continue

      // priorRelateSailing => related sailing is before our main sailing but after our nextSailings if we have one already
      const relatedSailingDate = moment(sailing.sailDate, sailDateFormat)
      const mainSailDate = moment(sailDate, sailDateFormat)
      if (
        relatedSailingDate.isBefore(mainSailDate) &&
        (!priorRelateSailing ||
          relatedSailingDate.isAfter(priorRelateSailing.sailDate))
      ) {
        priorRelateSailing = sailing
      }

      // nextSailings => related sailing is after our main sailing, we'll add them all here then filter and grab the first four
      if (relatedSailingDate.isAfter(mainSailDate)) {
        nextSailings.push(sailing)
      }
    }
    // sort and grab 4
    const sortedNextSailings = nextSailings
      .sort((a, b) => {
        if (
          moment(a.sailDate, sailDateFormat).isBefore(
            moment(b.sailDate, sailDateFormat),
            'day'
          )
        ) {
          return -1
        }
        return 1
      })
      .slice(0, 4)
    const activeSearchFilter = getState().activeSearchFilterData
    const { guestCount, currency } = activeSearchFilter
    const filteredRelatedSailing = priorRelateSailing
      ? [priorRelateSailing, ...sortedNextSailings]
      : sortedNextSailings

    /*
     * for each filteredRelatedSailing we need to make an sessionStorage call to two endpoints
     * /sessionStorage/cruise/FL07G016/pricing?brand=C&countryCode=USA&shipCode=FL&sailDate=2019-12-08
     * and
     * /sessionStorage/cruise/FL15G016?brand=C
     *
     * Because we will likely have duplicate id's and to avoid making duplicate calls to /sessionStorage/cruise/FL15G016?brand=C
     * we'll first sort our request into an array with only unique requests.
     * */

    const state = getState()
    const { transactionData } = state.ssoData
    const agencyId = transactionData.agency.agencyId
    const officeCode = transactionData.officeCode

    const relatedSailingsRequestArray = filteredRelatedSailing.reduce(
      (arr, relatedSailing) => {
        //  make cruise url
        const cruiseEndpoint = `${
          process.env.REACT_APP_FLOW_ENDPOINT_URL
        }cruise/${
          relatedSailing.id
        }?brand=${brand}&agencyId=${agencyId}&officeCode=${officeCode}`

        // make pricing url
        const pricingEndpoint = formatRequestForPricing(
          {
            id: relatedSailing.id,
            sailDate: relatedSailing.sailDate,
            shipCode,
            brand,
            agencyId,
            officeCode
          },
          {
            ...(activeSearchFilter || {}),
            countryCode: getAgentCountry(getState())
          }
        )

        if (!arr.some(request => request === cruiseEndpoint)) {
          arr.push(cruiseEndpoint)
        }
        if (!arr.some(request => request === pricingEndpoint)) {
          arr.push(pricingEndpoint)
        }
        return arr
      },
      []
    )

    const promiseList = relatedSailingsRequestArray.map(sailingRequest =>
      axios.get(sailingRequest)
    )
    /*
     * Now we can loop over this array and two endpoints
     * we'll add the results to an array we can store in redux
     */
    const relatedSailingsWithData = []
    await axios
      .all(promiseList)
      .then(responses => {
        for (const response of responses) {
          // get the pricing responses and find it's matching cruise data
          // only CruiseDetails have an id on the package so we'll skip those at first
          if (
            response &&
            response.data &&
            response.data.package &&
            !response.data.package.id
          ) {
            // grab the corresponding cruiseData
            const cruiseData = responses.find(
              res =>
                res.data &&
                res.data.package &&
                res.data.package.id &&
                res.data.package.id === response.data.id
            )

            const brand = get(cruiseData, 'data.brand')

            const pricing =
              response.data.package && brand
                ? formatGuestCountPricing(
                    response.data.package,
                    guestCount,
                    brand
                  )
                : {}

            const priceInfo = get(
              Object.values(pricing).find(
                price =>
                  !!get(price, 'bestGuestCountRate.priceInfo.taxesFess', {})
              ),
              'bestGuestCountRate.priceInfo',
              null
            )

            if (cruiseData && pricing && priceInfo) {
              const relatedSailing = {
                ...get(cruiseData, 'data.package', {}),
                ...get(response, 'data', {}),
                brand: get(cruiseData, 'package.brand', ''),
                justACruise: get(cruiseData, 'package.justACruise', true),
                pricing,
                priceInfo,
                stateroomTypes,
                currency,
                currencySymbol: nonStandardCurrencySymbols[currency] || '$'
              }
              delete relatedSailing.package

              relatedSailingsWithData.push(relatedSailing)
            }
          }
        }
      })
      .catch(() => {
        dispatch({
          type: RECEIVE_RELATED_SAILINGS,
          payload: {
            key: packageKey,
            info: relatedSailingsWithData
          }
        })
      })

    dispatch({
      type: RECEIVE_RELATED_SAILINGS,
      payload: {
        key: packageKey,
        info: relatedSailingsWithData
      }
    })
  }
}
