import axios from 'axios'
import 'url-search-params-polyfill'
import formatItinerary from '../utilities/formatItinerary'
export const FETCH_ITINERARY_BEGIN = 'FETCH_ITINERARY_BEGIN'
export const FETCH_ITINERARY_SUCCESS = 'FETCH_ITINERARY_SUCCESS'
export const FETCH_ITINERARY_FAILURE = 'FETCH_ITINERARY_FAILURE'

export const fetchItineraryBegin = () => ({
  type: FETCH_ITINERARY_BEGIN
})

export const fetchItinerarySuccess = (key, itinerary) => ({
  type: FETCH_ITINERARY_SUCCESS,
  payload: {
    key,
    itinerary
  }
})

export const fetchItineraryError = error => ({
  type: FETCH_ITINERARY_FAILURE,
  payload: { error }
})

// Handle Availability Fetch & Functionality
export default function fetchItinerary(
  brand,
  packageId,
  sailDate,
  packageIdentifier,
  shipCode,
  agencyId,
  officeCode
) {
  return dispatch => {
    // // Formatted API path including base and params
    const itineraryApi = `${
      process.env.REACT_APP_FLOW_ENDPOINT_URL
    }cruise/${packageId}/itinerary?brand=${brand}&sailDate=${sailDate}&ship=${shipCode}&agencyId=${agencyId}&officeCode=${officeCode}`

    dispatch(fetchItineraryBegin())
    return axios
      .get(itineraryApi)

      .then(response => {
        const availableItinerary = response.data
        if (
          availableItinerary &&
          availableItinerary.package &&
          availableItinerary.package.length
        ) {
          const formattedItinerary = formatItinerary(availableItinerary.package)
          dispatch(
            fetchItinerarySuccess(
              `${packageIdentifier}-${brand}`,
              formattedItinerary
            )
          )
        } else {
          dispatch(fetchItineraryError('Unable to retrieve itinerary data'))
        }
        return response
      })
      .catch(error => {
        dispatch(fetchItineraryError(error))
        return error
      })
  }
}
